import { useState } from "react";
import "./App.css";
import { ApolloProvider } from "@apollo/client/react";
import { gql } from "@apollo/client";

import client from "./lib/apollo";
import Grid from "@mui/material/Grid";
import MainMap from "./components/MainMap";
import ControlsColumn from "./components/ControlsColumn";
import "leaflet/dist/leaflet.css";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import { MapDataProvider } from "./data/MapContext";
import { PlaceContext, countryValues } from "./data/MapContext";
import useMediaQuery from "@mui/material/useMediaQuery";

const gridStyles = {
  backgroundColor: "#262626",
};

const el = document.getElementById('funding-map');
const version = el.getAttribute('data-version');
const GET_STATES = gql`
  query GetStates {
    ${version}
  }
`;

function App() {
  const [selectedPlace, setSelectedPlace] = useState(countryValues);
  const value = { selectedPlace, setSelectedPlace };
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <MapDataProvider query={GET_STATES}>
          <PlaceContext.Provider value={value}>
            <Grid container spacing={1} sx={gridStyles}>
              <Grid item xs>
                <MainMap />
              </Grid>
              <Grid
                item
                style={{
                  width: "535px",
                  position: "relative",
                  overflow: matches ? "hidden" : "auto",
                }}
              >
                <ControlsColumn />
              </Grid>
            </Grid>
          </PlaceContext.Provider>
        </MapDataProvider>
      </ThemeProvider>
    </ApolloProvider>
    </>
  );
}

export default App;
