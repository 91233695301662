import { useContext, useState, useMemo } from "react";
import Typography from "@mui/material/Typography";
import OffsetsList from "./OffsetsList";
import ActionButton from "./ActionButton";
import { PlaceContext, useMapData } from "../../data/MapContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";

import { ReactComponent as MapMarkerGrey } from "./OffsetsList/icons/mapmarker/grey.svg";
import { ReactComponent as Arrow } from "./OffsetsList/icons/arrow.svg";
import { gql, useQuery } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@mui/material/useMediaQuery";
import {SetCity} from "./utils";

const GET_CITIES_BY_STATE_CODE = gql`
  query SearchCitiesStates($text: String!) {
    mapCities(getCitiesByStateCode: $text)
  }
`;

const ListOfCitiesInState = ({ stateCode }) => {
  // const { setSelectedPlace } = useContext(PlaceContext);
  const [cityId, setCityId] = useState("");
  const [cities, setCities] = useState([]);
  const matches = useMediaQuery("(min-width:600px)");

  const { data, loading, error } = useQuery(GET_CITIES_BY_STATE_CODE, {
    variables: { text: stateCode },
  });
  if (error) console.log("error in GET_CITIES_BY_STATE_CODE", error);

  useMemo(() => {
    if (data) {
      let jsonData = JSON.parse(data.mapCities);
      jsonData = jsonData.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) => t.city === value.city && t.state_name === value.state_name
          )
      );

      const processedData = jsonData.map((item) => {
        return {
          label: `${item.city}, ${item.state_name}`,
          type: "city",
          ...item,
        };
      });
      setCities(processedData);
    }
  }, [data]);
  const cityClickHandler = (item) => {
    setCityId(item.id)
  };
  return (
    <Box style={{ maxHeight: matches ? "446px" : "200px", overflow: "auto" }}>
      <List>
        {loading ? (
          <Box textAlign="center" p={2}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          cities.map((item) => {
            return (
              <PlaceLinkListItem
                item={item}
                key={item.label}
                clickHandler={cityClickHandler}
              />
            );
          })
        )}
      </List>
      {!loading && (
        <Typography color="white" variant="body2" textAlign="center" p={2}>
          Don't see your town on this list? Type it in above
        </Typography>
      )}
      <SetCity id={cityId} />
    </Box>
  );
};

const currencyStringToNumber = (str) => Number(str.replace(/[^0-9.-]+/g, ""));

const ListOfStates = () => {
  const [states, setStates] = useState([]);
  const { data, loading, error } = useMapData();
  const { setSelectedPlace } = useContext(PlaceContext);
  const matches = useMediaQuery("(min-width:600px)");

  if (error) console.error("error", error);
  if (loading) console.log("loading", loading);
  useMemo(() => {
    if (data) {
      const mapType = data?.map ? data.map : data.mapBiden;
      const jsonData = JSON.parse(mapType);
      const processedData = jsonData.map((item) => {
        return { label: item.name, type: "state", ...item };
      });
      setStates(processedData);
    }
  }, [data]);

  const stateClickHandler = (item) => {
    const { name, contribution, code } = item;
    setSelectedPlace({
      type: "state",
      name,
      contribution: currencyStringToNumber(contribution),
      code,
    });
  };

  return (
    <List style={{ maxHeight: matches ? "446px" : "200px", overflow: "auto" }}>
      {states.map((item) => {
        return (
          <PlaceLinkListItem
            item={item}
            key={item.label}
            clickHandler={stateClickHandler}
          />
        );
      })}
    </List>
  );
};

const PlaceLinkListItem = ({ item, i, clickHandler }) => {
  return (
    <ListItem
      style={{ cursor: "pointer" }}
      divider
      secondaryAction={
        <IconButton edge="end" aria-label="stateLink">
          <Arrow />
        </IconButton>
      }
      onClick={() => clickHandler(item)}
    >
      <ListItemIcon>
        <MapMarkerGrey />
      </ListItemIcon>
      <Typography color="white" variant="subtitle2">
        {item.label}
      </Typography>
    </ListItem>
  );
};

const PanelContent = ({ value, actionsOpen, setActionsOpen }) => {
  const { selectedPlace } = useContext(PlaceContext);
  const matches = useMediaQuery("(min-width:600px)");

  const renderContent = () => {
    switch (value) {
      case 0:
        switch (selectedPlace.type) {
          case "state":
            return (
              <>
                <Typography>
                  Select your state or type it in the search bar above.
                </Typography>
              </>
            );
          case "city":
            // setSelectedPlace(countryValues);
            return;
          default:
            // setSelectedPlace(countryValues);
            return <OffsetsList />;
        }

      case 1:
        switch (selectedPlace.type) {
          case "country":
            return (
              <>
                <Typography color="white" variant="subtitle1">
                  Select your state or type it in the search bar above.
                </Typography>
                <ListOfStates />
              </>
            );
          case "city":
            return;
          default:
            return <OffsetsList />;
        }

      case 2:
        switch (selectedPlace.type) {
          case "country":
            return (
              <Typography color="white" variant="subtitle1">
                Type your state or city in the search bar to access state or
                city data.
              </Typography>
            );
          case "state":
            return (
              <>
                <Typography color="white" variant="subtitle1">
                  Select your city or type it in the search bar above.
                </Typography>
                <ListOfCitiesInState stateCode={selectedPlace.code} />
              </>
            );
          default:
            return <OffsetsList />;
        }

      default:
        return null;
    }
  };
  return (
    <>
      <Box
        sx={{ opacity: actionsOpen ? 0.5 : 1, marginBottom: matches ? 0 : 2 }}
      >
        {renderContent()}
      </Box>
      <ActionButton actionsOpen={actionsOpen} setActionsOpen={setActionsOpen} />
    </>
  );
};

export default PanelContent;
