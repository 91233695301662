import React, { createContext, useContext } from "react";
import { useQuery } from "@apollo/client";

const QueryResultContext = createContext({
  queryData: { loading: true },
  refetch: () => Promise.resolve({}),
});
export const MapDataProvider = ({ query, variables, children }) => {
  const { data, error, loading, refetch } = useQuery(query, { variables });
  const value = { queryData: { data, error, loading }, refetch };
  return (
    <QueryResultContext.Provider value={value}>
      {children}
    </QueryResultContext.Provider>
  );
};

export const useMapData = () => {
  const context = useContext(QueryResultContext);
  if (!context) {
    throw new Error("useMapData must be used within a QueryResultProvider");
  }
  return context.queryData;
};

export const PlaceContext = createContext({
  selectedPlace: {  },
  setSelectedPlace: () => {},
});

export const countryValues = {
  type: "country",
  name: "the U.S.",
  id:"",
  contribution:17900000000//3800000000 => * 4.710526315789474
}
