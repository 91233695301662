import { useEffect, useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { PlaceContext } from "../../data/MapContext";
import { US_TOTAL } from "../../constants";

const GET_CITY = gql`
  query SearchCitiesStates($text: String!) {
    mapCities(getCity: $text)
  }
`;

const cityContributionByHouseholds = ({
  housing_units,
  income_household_median,
  proportion_fed_tax,
  irs_reported_income,
}) => {
  return (US_TOTAL / 103863921) * housing_units;
};

export const SetCity = ({ id }) => {
  const { setSelectedPlace } = useContext(PlaceContext);

  const { data, loading, error } = useQuery(GET_CITY, {
    variables: { text: id },
  });
  if (error) {
    console.log("updateCityError in util", loading, error);
  }
  useEffect(() => {
    if (id !== "" && data?.mapCities) {
      const updateCityDataJson = JSON.parse(data.mapCities);
      if (updateCityDataJson.length > 0) {
        const {
          city: name,
          lat,
          lng,
          housing_units,
          state_id,
          state_name,
          income_household_median,
          proportion_fed_tax,
          irs_reported_income,
        } = updateCityDataJson[0];
        setSelectedPlace({
          type: "city",
          name: `${name}, ${state_name}`,
          latLng: [parseFloat(lat), parseFloat(lng)],
          contribution: cityContributionByHouseholds({
            housing_units: parseInt(housing_units),
            income_household_median,
            proportion_fed_tax,
            irs_reported_income,
          }),
          state_id,
          state_name,
        });
      }
    }
  }, [id, data?.mapCities, setSelectedPlace]);
};
